<template>
  <v-breadcrumbs
    class="font font-weight-medium font-size-md mt-n4"
    :items="items"
  >
    <template #items="{item}">
      <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
<script>
export default {
  name: "BreadCrumb",
  props: {
    items: Array,
    default: () => []
  }
};
</script>
